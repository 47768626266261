@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

.card-container {
	display: flex;
	align-items: center;
	width: 30rem;
	height: 15rem;
	margin-top: 20px;
	margin-right: 60px;
	justify-content: space-around;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

.card-container:hover {
	background-image: linear-gradient(to right, black, #ee964b);
	transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

.left {
	margin-left: 10px;
}

.right {
	width: 50%;
	margin-right: 10px;
}

.photo {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.name {
	font-size: 1.3rem;
	font-weight: bold;
	font-family: "Ubuntu", sans-serif;
}

.socials {
	margin-top: 20px;
	display: flex;
	justify-content: space-evenly;
	font-size: 1.5rem;
}

.socials > div:hover {
	cursor: pointer;
	color: #fca311;
}

@media screen and (max-width: 988px) {
	.card-container {
		margin-right: 0px;
	}
	.card-container:hover {
		background-image: none;
	}
}
