@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,400,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@100;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,400,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap);
* {
	font-family: "Roboto", sans-serif;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

* {
	font-family: "Roboto", sans-serif;
}

.App {
	text-align: center;
	min-height: 100vh;
}
.content-container {
	min-height: calc(100vh - 50px);
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ffab3d;
	border-radius: 4px;
}

.navbar {
	position: absolute;
	z-index: 2;
	width: 100%;
	background-color: #fff;
	color: #ffab3d;
	min-height: 50px;
}
.navbar-brand {
	color: #ffab3d;
}
.nav-option {
	font-weight: 400;
	font-size: 1.2rem;
	color: #000;
}
.nav-option:hover {
	color: #f77f00;
}
.wrapper {
	height: 100vh;
	display: flex;
	flex-direction: row;
}

.wrapper .one {
	background-color: #000;
	display: flex;
	align-items: center;
	width: 60vw;
}

.wrapper .two {
	background-color: #ee964b;
	position: relative;
	display: flex;
	align-items: center;
	width: 40vw;
}

.wrapper .one .heading {
	text-align: left;
	/* color: white; */
	color: #ffab3d;
	margin-left: 15rem;
}

.wrapper .one .heading h6 {
	font-size: 2.5rem;
	font-weight: 300;
	line-height: 75px;
}

.wrapper .one .heading h1 {
	font-size: 5rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 900;
}

.wrapper .one .heading button {
	padding: 0.5rem 1.5rem 0.5rem 1.5rem;
	border-radius: 44px;
	border: none;
	outline: none;
	/* background-color: white !important; */
	background-color: #ffab3d;
	color: #fff;
	-webkit-transition: 0.5s ease-in;
	transition: 0.5s ease-in;
}

.wrapper .one .heading button a {
	color: #ffab3d;
	text-decoration: none;
}

.wrapper .one .heading button:hover {
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.wrapper .two .overflow {
	width: 250px;
	height: 250px;
	padding: 20px;
	border-radius: 125px;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.wrapper .two .overflow img {
	width: 60%;
	height: auto;
}

@media only screen and (max-width: 850px) {
	.wrapper .one .heading {
		margin-left: 2rem;
	}

	.wrapper .two .overflow {
		display: block;
		margin-right: 4rem;
		width: 160px;
		height: 200px;
		border-radius: 100px;
	}

	.wrapper .two .overflow img {
		width: 60%;
		height: auto;
	}
}

@media only screen and (max-width: 768px) {
	.navbar {
		width: 18.5;
	}
	.wrapper {
		background-image: -webkit-gradient(linear, left top, right top, from(black), to(orange));
		background-image: linear-gradient(to right, black, orange);
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
	}

	.wrapper .one {
		flex: 2 1;
		width: 100vw;
		height: 60vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.wrapper .one .heading {
		text-align: center;

		margin-left: 0px;
	}

	.wrapper .one .heading h6 {
		font-size: 1.5rem;
		font-weight: 300;
		line-height: 0px;
	}

	.wrapper .one .heading h1 {
		font-size: 4rem;
		font-family: "Montserrat", sans-serif;
		font-weight: bold;
	}

	.wrapper .two {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 40vh;
	}

	.wrapper .two .overflow {
		width: 150px;
		height: 150px;
		padding: 20px;
		border-radius: 75px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		position: absolute;
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
		-webkit-transform: translateY(-90%);
		        transform: translateY(-90%);
	}

	.wrapper .one .heading button {
		display: block;
		margin-left: auto;
		margin-right: auto;
		border-radius: 44px;
		border: none;
		outline: none;
		background-color: white;
		color: #ffab3d;
		-webkit-transition: 0.5s ease-in;
		transition: 0.5s ease-in;
		position: absolute;
		bottom: 50px;
		z-index: 10;
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}
}

.register-container {
	min-height: calc(100vh - 100px);
}
.input-field {
	border-radius: 50px;
	border: 2px solid #535353;
	padding: 10px 15px;
	display: flex;
	align-items: center;
}
.input-field > span {
	padding-right: 10px;
	font-size: 1.2rem;
}
.input {
	outline: none;
	border: none;
	width: 100%;
	background: transparent;
	font-size: 1.2rem;
	font-family: sans-serif;
}
::-webkit-input-placeholder {
	/* Edge */
	color: #000;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #000;
}

::-moz-placeholder {
	color: #000;
}

::-ms-input-placeholder {
	color: #000;
}

::placeholder {
	color: #000;
}
.form > div {
	margin-top: 20px;
	font-family: sans-serif;
	font-size: 1rem;
}
option:disabled {
	color: #737373;
}
.label {
	position: fixed;
}

.card-container {
	display: flex;
	align-items: center;
	width: 30rem;
	height: 15rem;
	margin-top: 20px;
	margin-right: 60px;
	justify-content: space-around;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	-webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
	transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

.card-container:hover {
	background-image: -webkit-gradient(linear, left top, right top, from(black), to(#ee964b));
	background-image: linear-gradient(to right, black, #ee964b);
	-webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
	transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

.left {
	margin-left: 10px;
}

.right {
	width: 50%;
	margin-right: 10px;
}

.photo {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.name {
	font-size: 1.3rem;
	font-weight: bold;
	font-family: "Ubuntu", sans-serif;
}

.socials {
	margin-top: 20px;
	display: flex;
	justify-content: space-evenly;
	font-size: 1.5rem;
}

.socials > div:hover {
	cursor: pointer;
	color: #fca311;
}

@media screen and (max-width: 988px) {
	.card-container {
		margin-right: 0px;
	}
	.card-container:hover {
		background-image: none;
	}
}

.team-wrapper {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	min-height: calc(100vh - 100px);
}
.team-container {
	width: 90%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.team-title {
	font-family: "Ubuntu", sans-serif;
	font-weight: bold;
	font-size: 2rem;
}

.define {
	padding-left: 2rem;
	padding-right: 2em;
}

#define-para {
	color: yellow;
	background-color: black;
	min-height: 16rem;
}

.teamQuote {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin-bottom: 2rem;
}

#quote {
	font-family: "Dancing Script", cursive;
	font-size: 2rem;
}

#quoteMark {
	font-size: 6rem;
	font-family: "Catamaran", sans-serif;
}

#quoteBy {
	font-family: "Dancing Script", cursive;
	font-size: 2.6rem;
}

@media only screen and (max-width: 767px) {
	.teamQuote {
		padding: 10px;
	}

	#quote {
		font-size: 2rem;
	}

	#quoteBy {
		font-size: 1.4rem;
	}
	#quoteMark {
		font-size: 3rem;
	}
}

.section {
	height: 100vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.section .one {
	background: -webkit-gradient(linear, left top, left bottom, from(#000), to(#ffffff));
	background: linear-gradient(#000, #ffffff);
	background-color: #ee964b;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60vw;
	height: 100vh;
}

.section .two {
	background: -webkit-gradient(linear, left top, left bottom, from(#ee964b), to(#ffffff));
	background: linear-gradient(#ee964b, #ffffff);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 40vw;
	height: 100vh;
}
.home-team-img {
	width: 45vw;
}
.contents {
	width: 30vw;
	text-align: left;
	font-family: "Ubuntu", serif;
	font-size: 1.5rem;
	margin-bottom: 1rem;
}
.contents.c0 {
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
}
.button {
	width: 8.7rem;
	background-color: #4f4f4f;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 20px;
	text-decoration: none;
}
.button:hover {
	cursor: pointer;
	background-color: #363636;
}
a,
a:hover {
	text-decoration: none;
}
@media only screen and (max-width: 767px) {
	.section {
		margin-left: 0;
		background-image: -webkit-gradient(linear, left top, right top, from(black), to(orange));
		background-image: linear-gradient(to right, black, orange);
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
	}

	.section .one {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 50vh;
	}

	.section .two {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 50vh;
	}
	.contents {
		width: 90vw;
		font-size: 1rem;
	}
	.home-team-img {
		width: 95vw;
	}

	.section .two button {
		display: none;
	}
}

.courses-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 20px;
}
.logo-img {
	width: 100px;
	height: 100px;
}
.allCourse-title {
	font-size: 2rem;
	font-family: "Ubuntu", sans-serif;
	margin-top: 20px;
	font-weight: bold;
}

@media only screen and (max-width: 767px){
	.allCourse-title{
		margin-top: 20px;
	}
}



.course-container {
	width: 25rem;
	height: 15rem;
	margin-top: 10px;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
}

.course-title {
	margin-top: 20px;
	font-size: 1.2rem;
	font-family: "Ubuntu", sans-serif;
}
.course-container:hover {
	cursor: pointer;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
@media screen and (max-width: 988px) {
	.course-container:hover {
		box-shadow: none;
	}
}

.footer-container {
	display: flex;
	width: 100%;
	height: 50px;
	justify-content: space-evenly;
	align-items: center;
	font-size: 0.9rem;
	background-color: #000;
	color: #edf6f9;
	font-family: "Ubuntu", sans-serif;
}
.footer-container > div {
	width: 30%;
}
.copyright-content,
.made-by {
	font-family: "Ubuntu", sans-serif;
}
.footer-socials {
	display: flex;
	align-items: center;
	font-size: 1.1rem;
	justify-content: space-evenly;
}
/* .footer-socials > div {
	margin-right: 20px;
} */
.footer-socials > div:hover {
	color: #f77f00;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
.heart {
	color: red;
}
@media only screen and (max-width: 950px) {
	.footer-container {
		flex-direction: column;
		height: 150px;
	}
	.footer-container > div {
		width: 100%;
	}
	.footer-socials {
		justify-content: space-evenly;
		flex-wrap: wrap;
	}
	.footer-socials > div {
		margin-right: 0px;
		color: #f77f00;
	}
}

