

.course-container {
	width: 25rem;
	height: 15rem;
	margin-top: 10px;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
}

.course-title {
	margin-top: 20px;
	font-size: 1.2rem;
	font-family: "Ubuntu", sans-serif;
}
.course-container:hover {
	cursor: pointer;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
@media screen and (max-width: 988px) {
	.course-container:hover {
		box-shadow: none;
	}
}
