.courses-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 20px;
}
.logo-img {
	width: 100px;
	height: 100px;
}
.allCourse-title {
	font-size: 2rem;
	font-family: "Ubuntu", sans-serif;
	margin-top: 20px;
	font-weight: bold;
}

@media only screen and (max-width: 767px){
	.allCourse-title{
		margin-top: 20px;
	}
}
